<template>
  <div>
    <el-dialog
      :visible.sync="showCodeProductDialog"
      :show-close="false"
      :close-on-click-modal="false"
      custom-class="code-product-dialog app-page-scala"
      @close="handleCloseDialog"
      v-if="webMode === 1"
    >
      <div class="code-product-info">
        <div class="code-product-dialog-title">
          {{ getLangContent(codeProductData.title) }}
          <img class="code-product-dialog-close" src="/imgStatic/closeIcon.png" alt="" @click="handleCloseDialog" />
        </div>
        <div class="code-product-info-title">{{ getLangContent(codeProductData.subTitle) }}</div>
        <div class="code-product-dialog-content">
          <el-row class="code-product-dialog-content-row">
            <el-col :span="14" class="code-product-info-left">
              <div class="code-product-info-content">{{ getLangContent(codeProductData.description) }}</div>
              <div class="code-product-info-refrigerant">{{ `${$t("productsData.refrigerantType")}${codeProductData.type}` }}</div>
              <div class="code-product-info-charact html-data-box" v-html="getLangContent(codeProductData.content)">
              </div>
            </el-col>
            <el-col :span="10" class="code-product-info-right">
              <img :src="codeProductData.picture" alt="">
            </el-col>
          </el-row>
          <div class="code-product-info-conent">
            <div class="code-product-info-conent-node">
              <img :src="getLangContent(codeProductData.filename)" alt="">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showCodeProductDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :fullscreen="true"
      custom-class="code-product-mobile-dialog"
      @close="handleCloseDialog"
      v-if="webMode === 2"
    >
      <div class="code-product-info">
        <div class="code-product-dialog-title">
          <img class="code-product-dialog-close" src="/imgStatic/closeIcon.png" alt="" @click="handleCloseDialog" />
        </div>
        <div class="code-product-dialog-content">
          <el-row>
            <el-col :span="24" class="code-product-info-right">
              <img :src="codeProductData.picture" alt="">
            </el-col>
            <el-col :span="24" class="code-product-info-left">
              <div class="code-product-info-dialog-title">{{ getLangContent(codeProductData.title) }}</div>
              <div class="code-product-info-title">{{ getLangContent(codeProductData.subTitle) }}</div>
              <div class="code-product-info-content">{{ getLangContent(codeProductData.description) }}</div>
              <div class="code-product-info-refrigerant">{{ `${$t("productsData.refrigerantType")}${codeProductData.type}` }}</div>
              <div class="code-product-info-charact html-data-box" v-html="getLangContent(codeProductData.content)">
              </div>
            </el-col>
          </el-row>
          <div class="code-product-info-conent">
            <div class="code-product-info-conent-node">
              <img :src="getLangContent(codeProductData.filename)" alt="">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div id="dialogScala" v-html="dialogScalaStyle"></div>
  </div>
</template>

<script>

export default {
    name: 'CodeProduct',
    props: {
        showCodeProductDialog: Boolean,
        productData: Object,
    },
    components: {
    },
    data() {
      return {
        codeProductData: {},
        dialogScalaStyle: '',
      };
    },
    created() {},
    methods: {
      handleCloseDialog() {
        this.$emit('closeProductDialog');
      },
    },
    computed: {
      webMode() {
        return this.$store.state.webMode;
      },
      lang() {
        return this.$store.state.lang;
      },
      scala() {
        return this.$store.state.scala;
      },
    },
    watch: {
      productData: {
        immediate: true,
        handler(val) {
          this.codeProductData = val;
        },
      },
      scala: {
        immediate: true,
        handler(val, oldVal) {
          console.log(val, oldVal);
          if (this.val !== 1 && this.webMode === 1) {
            this.dialogScalaStyle = `<style>
              .code-product-dialog {
                width: ${50 / val}% !important;
                left: ${50 - 25 / val}% !important;
              }
            </style>`;
          }
        },
      },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  /deep/.code-product-dialog {
    position: fixed;
		left: 50%;
		top: calc(50% - 370px);
		transform: translate(-50%,-50%);
    margin-top: 0 !important;
    width: 1000px;
    height: 740px;
    border: none;
    border-radius: 0;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      height: 100%;
      padding: 0;
      word-break: break-word;
      .code-product-info {
        height: 100%;
        .code-product-dialog-title {
          display: flex;
          align-items: center;
          margin-top: 50px;
          padding: 0 50px;
          font-size: 36px;
          font-weight: bold;
          line-height: 36px;
          color: #333333;
          .code-product-dialog-close {
            margin-left: auto;
            width: 32px;
            cursor: pointer;
          }
        }
        .code-product-info-title {
          margin: 16px 0 37px;
          padding: 0 50px;
          font-size: 20px;
          font-weight: bold;
          color: #C3002F;
          line-height: 20px;
        }
        .code-product-dialog-content {
          height: calc(100% - 159px);
          overflow-y: scroll;
          padding: 0 50px;
          .code-product-dialog-content-row {
            display: flex;
            align-items: center;
            .code-product-info-left {
              .code-product-info-content {
                font-size: 18px;
                color: #333333;
                line-height: 30px;
              }
              .code-product-info-refrigerant {
                margin: 30px 0 25px;
                font-size: 18px;
                color: #C3002F;
                line-height: 30px;
              }
              .code-product-info-charact {
                font-size: 18px;
                color: #333333;
                line-height: 30px;
                li {
                  // margin: -10px 0;
                }
                .code-product-info-charact-node {
                  display: flex;
                  align-items: flex-start;
                  font-size: 18px;
                  color: #333333;
                  line-height: 30px;
                  font {
                    margin-right: 5px;
                  }
                  img {
                    margin-right: 12px;
                  }
                }
              }
            }
            .code-product-info-right {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 300px;
                max-height: 360px;
              }
            }
          }
          .code-product-info-conent {
            margin-top: 40px;
            .code-product-info-conent-node {
              margin-bottom: 25px;
              .code-product-info-conent-node-title {
                margin-bottom: 20px;
                font-size: 18px;
                color: #C3002F;
                line-height: 18px;
              }
              img {
                max-width: 100%;
              }
            }
          }
        }
        ::-webkit-scrollbar {
            width: 6px;
        }
        ::-webkit-scrollbar-thumb {
          background: grey;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-track {
          background: #dcdcdc;
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb:hover{
          background: #555;
        }
      }
    }
  }
  /deep/.code-product-mobile-dialog {
    height: 100%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      word-break: break-word;
      .code-product-info {
        height: 100%;
        .code-product-dialog-title {
          display: flex;
          align-items: center;
          padding: 0 30px;
          padding-top: 30px;
          .code-product-dialog-close {
            margin-left: auto;
            width: 21px;
            height: 20px;
            cursor: pointer;
          }
        }
        .code-product-dialog-content {
          padding: 0 30px;
          height: calc(100% - 50px);
          overflow-y: scroll;
          .code-product-info-left {
            .code-product-info-dialog-title {
              margin-top: 50px;
              font-size: 23px;
              font-weight: bold;
              color: #333333;
              line-height: 23px;
            }
            .code-product-info-title {
              margin: 5px 0 23px;
              font-size: 16px;
              font-weight: bold;
              color: #C3002F;
              line-height: 26px;
            }
            .code-product-info-content {
              font-size: 16px;
              color: #333333;
              line-height: 23px;
            }
            .code-product-info-refrigerant {
              margin: 30px 0 25px;
              font-size: 16px;
              color: #C3002F;
              line-height: 23px;
            }
            .code-product-info-charact {
              font-size: 16px;
              color: #333333;
              line-height: 25px;
              li {
                margin: -6px 0;
              }
              .code-product-info-charact-node {
                display: flex;
                align-items: flex-start;
                font-size: 16px;
                color: #333333;
                line-height: 25px;
                font {
                  margin-right: 5px;
                }
                img {
                  margin-right: 8px;
                  width: 16px;
                }
              }
            }
          }
          .code-product-info-right {
            display: flex;
            justify-content: center;
            img {
              max-width: 300px;
              max-height: 360px;
            }
          }
          .code-product-info-conent {
            margin-top: 30px;
            .code-product-info-conent-node {
              margin-bottom: 15px;
              .code-product-info-conent-node-title {
                margin-bottom: 20px;
                font-size: 16px;
                color: #C3002F;
                line-height: 19px;
              }
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .app-mobile {
  }
</style>
