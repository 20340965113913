<template>
	<div class="home">
		<div class="content-box hidden-sm-and-down" v-if="webMode === 1">
			<div class="lbt-dom">
				<el-carousel trigger="click" :height="adHeight" class="lbt-carousel" :autoplay="adAutoPlay" @change="handleAdChange">
					<el-carousel-item v-for="item in adList" :key="item.id">
						<div class="lbt-carousel-node">
							<img :src="item.url.pc" alt="">
							<div class="lbt-carousel-node-content-box">
								<div class="lbt-carousel-node-content-box-dom page-content-box">
									<div class="lbt-carousel-node-content">
										<div class="lbt-carousel-node-title" v-text="getLangContent(item.title)"></div>
										<div class="lbt-carousel-node-desc" v-text="getLangContent(item.description)"></div>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<!-- <img class="lbt-dom-slogan" src="/imgStatic/home-slogan.png" /> -->
				<div class="lbt-carousel-node-more-box" @mouseenter="handleAdMouseenter" @mouseleave="handleAdMouseLeave">
					<div class="lbt-carousel-node-more-box-dom page-content-box">
						<div class="lbt-carousel-node-more" @click="handleToAd">
							<div class="lbt-carousel-node-more-content">
								<div class="lbt-carousel-node-more-content-text">{{ getLangContent(adMoreBtnConfig) }}</div>
								<div class="arrow-anim__arrow arrow-anim__arrow--right">
									<div class="arrow-anim__line"></div>
									<div class="arrow-anim__image"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="intro-box about-box">
				<div class="page-content-box">
					<div class="intro-box-left">
						<div class="intro-box-title">{{ aboutAreaData.title }}</div>
						<div class="intro-box-content html-data-box" v-html="aboutAreaData.description"></div>
						<div class="btn intro-box-more" @click="handleToPage(aboutAreaData.buttonLink)">{{ aboutAreaData.buttonText }}</div>
					</div>
					<div class="intro-box-right">
						<img :src="item.img" v-for="(item, index) in (aboutAreaData.pictures || [])" :key="index" alt="">
					</div>
				</div>
			</div>
			<div class="intro-box news-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ newsAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="newsAreaData.description"></div>
					<div class="intro-box-list">
						<el-row :gutter="25">
							<el-col :span="8" v-for="item in newsList" :key="item.id">
								<div class="intro-box-list-node" @click="handleToPage(`/newsDetail?id=${item.id}`, true)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover" alt="">
									</div>
									<div class="intro-box-list-node-date" v-text="getLangContent(item.publishDate)"></div>
									<div class="intro-box-list-node-title" v-text="getLangContent(item.title)"></div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="btn intro-box-more" @click="handleToPage(newsAreaData.buttonLink)">{{ newsAreaData.buttonText }}</div>
				</div>
			</div>
			<div class="intro-box product-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ codeProductAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="codeProductAreaData.description"></div>
					<div class="intro-box-list">
						<el-row :gutter="48">
							<el-col :span="8" v-for="item in productList" :key="item.id">
								<div class="intro-box-list-node" @click="handleShowProductInfo(item)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover.pc" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<!-- <div class="intro-box-list-node-content-category" v-text="item.category"></div> -->
										<img src="/imgStatic/product-title-bg.png" alt="">
										<div class="intro-box-list-node-content-name" v-text="getLangContent(item.title)"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="btn intro-box-more" @click="handleToPage(codeProductAreaData.buttonLink)">{{ codeProductAreaData.buttonText }}</div>
				</div>
			</div>
			<div class="contract-box">
				<img src="/imgStatic/contract-page-bg.png" class="contract-box-bg" alt="">
				<div class="contract-box-content">
					<div class="contract-box-content-title">{{ $t("homeData.contractIntroData.title") }}</div>
					<div class="contract-box-content-desc">{{ $t("homeData.contractIntroData.content1") }}</div>
					<div class="contract-box-content-desc">{{ $t("homeData.contractIntroData.content2") }}</div>
					<div class="contract-box-content-more" @click="handleToPage('/contract', true)">
						<div class="contract-box-content-more-box">
							<div class="contract-box-content-more-box-text">{{ $t("pageDom.contractBtn") }}</div>
							<div class="arrow-anim__arrow arrow-anim__arrow--right">
								<div class="arrow-anim__line"></div>
								<div class="arrow-anim__image"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-content-box hidden-md-and-up" v-if="webMode === 2">
			<div class="lbt-dom">
				<el-carousel trigger="click" :height="adMobileHeight" class="lbt-carousel" @change="handleAdChange">
					<el-carousel-item v-for="item in adList" :key="item.id">
						<div class="lbt-carousel-node">
							<img :src="item.url.h5" alt="">
							<div class="lbt-carousel-node-content">
								<div class="lbt-carousel-node-title" v-text="getLangContent(item.title)"></div>
								<div class="lbt-carousel-node-desc" v-text="getLangContent(item.description)"></div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="lbt-carousel-node-more-box">
					<div class="lbt-carousel-node-more-box-dom page-content-box">
						<div class="lbt-carousel-node-more" @click="handleToAd">
							<div class="lbt-carousel-node-more-content">
								<div class="lbt-carousel-node-more-content-text">{{ getLangContent(adMoreBtnConfig) }}</div>
								<div class="arrow-anim__arrow arrow-anim__arrow--right">
									<div class="arrow-anim__line"></div>
									<div class="arrow-anim__image"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="intro-box about-box">
				<div class="page-content-box">
					<div class="intro-box-left">
						<div class="intro-box-title">{{ aboutAreaData.title }}</div>
						<div class="intro-box-content html-data-box" v-html="aboutAreaData.description"></div>
						<div class="btn intro-box-more" @click="handleToPage(aboutAreaData.buttonLink)">{{ aboutAreaData.buttonText }}</div>
					</div>
					<div class="intro-box-right">
						<img :src="item.img" v-for="(item, index) in (aboutAreaData.pictures || [])" :key="index" alt="">
					</div>
				</div>
			</div>
			<div class="intro-box news-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ newsAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="newsAreaData.description"></div>
					<div class="intro-box-list">
						<el-row>
							<el-col :span="24" v-for="item in newsList" :key="item.id">
								<div class="intro-box-list-node" @click="handleToPage(`/newsDetail?id=${item.id}`, true)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover" alt="">
									</div>
									<div class="intro-box-list-node-date" v-text="getLangContent(item.publishDate)"></div>
									<div class="intro-box-list-node-title" v-text="getLangContent(item.title)"></div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="btn intro-box-more" @click="handleToPage(newsAreaData.buttonLink)">{{ newsAreaData.buttonText }}</div>
				</div>
			</div>
			<div class="intro-box product-box">
				<div class="page-content-box">
					<div class="intro-box-title">{{ codeProductAreaData.title }}</div>
					<div class="intro-box-content html-data-box" v-html="codeProductAreaData.description"></div>
					<div class="intro-box-list">
						<el-row :gutter="18">
							<el-col :span="12" v-for="item in productList" :key="item.id">
								<div class="intro-box-list-node" @click="handleShowProductInfo(item)">
									<div class="intro-box-list-node-img">
										<img :src="item.cover.h5" alt="">
									</div>
									<div class="intro-box-list-node-content">
										<!-- <div class="intro-box-list-node-content-category" v-text="item.category"></div> -->
										<img src="/imgStatic/product-title-bg.png" alt="">
										<div class="intro-box-list-node-content-name" v-text="getLangContent(item.title)"></div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="btn intro-box-more" @click="handleToPage(codeProductAreaData.buttonLink)">{{ codeProductAreaData.buttonText }}</div>
				</div>
			</div>
			<div class="contract-box">
				<img src="/imgStatic/contract-mobile-bg.png" class="contract-box-bg" alt="">
				<div class="page-content-box contract-box-content">
					<div class="contract-box-content-title">{{ $t("homeData.contractIntroData.title") }}</div>
					<div class="contract-box-content-desc">{{ $t("homeData.contractIntroData.content1") }}</div>
					<div class="contract-box-content-desc">{{ $t("homeData.contractIntroData.content2") }}</div>
					<div class="contract-box-content-more" @click="handleToPage(item.path, true)">
						<div class="contract-box-content-more-box">
							<div class="contract-box-content-more-box-text">{{ $t("pageDom.contractBtn") }}</div>
							<div class="arrow-anim__arrow arrow-anim__arrow--right">
								<div class="arrow-anim__line"></div>
								<div class="arrow-anim__image"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CodeProduct
			:showCodeProductDialog="showCodeProductDialog"
			:productData="codeProductDialogData"
			@closeProductDialog="handleCloseProductDialog"
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import CodeProduct from '@/components/CodeProduct.vue';

import {
	getAd,
	getSeries,
	getPageFragment,
	getNewsList,
} from '@/api/common';

export default {
	name: 'Home',
	components: {
		CodeProduct,
	},
	data() {
		return {
			pageUrl: '/home',
			cId: 5,
			adList: [],
			adMoreBtnConfig: {},
			adHeight: null,
			adMobileHeight: null,
			aboutAreaData: {},
			newsAreaData: {},
			codeProductAreaData: {},
			newsList: [],
			productList: [],
			showProductData: {},
			showCodeProductDialog: false,
			codeProductDialogData: {},
			currentAdIndex: 0,
			adAutoPlay: true,
		};
	},
	created() {
		this.init();
	},
	beforeMount() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	mounted() {
		this.handleResize();
	},
	methods: {
		init() {
			this.getSeriesFunc();
			this.getNewsListFunc();
		},
		getAdFunc() {
			getAd({
				id: this.adId,
			}).then(res => {
				this.adList = (res || {}).items;
				this.adMoreBtnConfig = (res || {}).buttonName;
			});
		},
		getSeriesFunc() {
			getSeries().then(res => {
				this.productList = res || [];
			});
		},
		getPageFragmentFunc() {
			getPageFragment({
				cid: this.cId,
				lang: this.keyConfig[this.lang],
			}).then(res => {
				(res || []).forEach(item => {
					switch (item.code) {
						// 关于我们板块内容
						case 'key-5-1':
							this.aboutAreaData = item;
							break;
						// 新闻资讯板块内容
						case 'key-5-2':
							this.newsAreaData = item;
							break;
						// 核心产品应用领域板块内容
						case 'key-5-3':
							this.codeProductAreaData = item;
							break;
						default:
							break;
					}
				});
			});
		},
		getNewsListFunc() {
            getNewsList({
                page: 1,
                size: 3,
            }).then(res => {
                this.newsList = res.records || [];
			});
        },
		handleResize() {
			const zoom = document.body.style.zoom || 1;
			this.adHeight = `${((document.documentElement.scrollWidth * 640) / 1920) / zoom}px`;
			this.adMobileHeight = `${(document.documentElement.clientWidth * 533) / 414}px`;
		},
		handleToPage(path, newTarget) {
			if (newTarget) {
				window.open(path);
			} else {
				window.location.href = path;
			}
		},
		handleShowProductInfo(item) {
			this.showCodeProductDialog = true;
			this.codeProductDialogData = item;
		},
		handleCloseProductDialog() {
			this.showCodeProductDialog = false;
			this.codeProductDialogData = {};
		},
		handleAdChange(val) {
			this.currentAdIndex = val;
		},
		handleAdMouseenter() {
			this.adAutoPlay = false;
		},
		handleAdMouseLeave() {
			this.adAutoPlay = true;
		},
		handleToAd() {
			const { path } = this.adList[this.currentAdIndex];

			window.location.href = path;
		},
	},
	computed: {
		webMode() {
			return this.$store.state.webMode;
		},
        screenwidth() {
            return this.$store.state.webMode;
        },
		lang() {
			return this.$store.state.lang;
		},
		keyConfig() {
			return this.$store.state.keyConfig;
		},
		menuConfig() {
			return this.$store.state.menuConfig;
		},
		adId() {
			const { adId } = (this.menuConfig || []).find(item => item.position === 'main' && item.url === this.pageUrl) || {};

			return adId || '';
		},
    },
	watch: {
		adId: {
			immediate: true,
			handler(val) {
				if (val) {
					this.getAdFunc();
					this.getPageFragmentFunc();
				}
			},
		},
    },
};
</script>

<style scoped lang='scss'>
	.home {
		width: 100%;
		.content-box {
			.arrow-anim__arrow {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				margin-left: 18px;
				padding-top: 2px;
				.arrow-anim__line {
					width: 18px;
					height: 2px;
					background: #fff;
					-webkit-transition: transform .3s ease-in-out;
					-webkit-transition: -webkit-transform .3s ease-in-out;
					transition: -webkit-transform .3s ease-in-out;
					transition: transform .3s ease-in-out;
					transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
				}
				.arrow-anim__image {
					border: solid #fff;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
					-webkit-transition: transform .2s ease-in-out;
					-webkit-transition: -webkit-transform .2s ease-in-out;
					transition: -webkit-transform .2s ease-in-out;
					transition: transform .2s ease-in-out;
					transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
					-webkit-transform-origin: center;
					transform-origin: center;
				}
			}
			.arrow-anim__arrow--right {
				.arrow-anim__line {
					-webkit-transform-origin: left center;
					transform-origin: left center;
				}
				.arrow-anim__image {
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
					margin-left: -7px;
				}
			}
			.lbt-dom {
				position: relative;
				width: 100%;
				z-index: 10;
				.lbt-dom-slogan {
					position: absolute;
					top: 41%;
					left: 1.823%;
					z-index: 10;
					// font-size: 12px;
					// color: #CCCDD0;
					// user-select: none;
					// transform:rotate(-90deg);
					// -ms-transform:rotate(-90deg); /* Internet Explorer */
					// -moz-transform:rotate(-90deg); /* Firefox */
					// -webkit-transform:rotate(-90deg); /* Safari 和 Chrome */
					// -o-transform:rotate(-90deg); /* Opera */
				}
				.lbt-carousel-node-more-box {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					z-index: 1000;
					.lbt-carousel-node-more-box-dom {
						.lbt-carousel-node-more {
							// position: absolute;
							// left: 18.07%;
							// bottom: 0;
							width: 448px;
							height: 208px;
							background: #C3002F;
							z-index: 1000;
							cursor: pointer;
							.lbt-carousel-node-more-content {
								display: flex;
								align-items: center;
								padding-top: 27px;
								.lbt-carousel-node-more-content-text {
									margin-left: 30px;
									font-size: 24px;
									font-weight: 500;
									color: #FFFFFF;
									line-height: 24px;
								}
							}
						}
						.lbt-carousel-node-more:hover {
							.arrow-anim__line {
								-webkit-transform: scaleX(1.4);
								transform: scaleX(1.4);
							}
							.arrow-anim__image {
								-webkit-transform: translateX(6px) rotate(-45deg) !important;
								transform: translateX(6px) rotate(-45deg) !important;
							}
						}
					}
				}
				.lbt-carousel {
					/deep/.el-carousel__container {
						.el-carousel__arrow {
							display: none;
						}
					}
					/deep/.el-carousel__indicators {
						display: none;
					}
					.lbt-carousel-node {
						position: relative;
						img {
							display: block;
							width: 100%;
						}
						.lbt-carousel-node-content-box {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							.lbt-carousel-node-content-box-dom {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								height: 100%;
								.lbt-carousel-node-content {
									margin-top: 70px;
									.lbt-carousel-node-title {
										width: 463px;
										font-size: 54px;
										font-weight: bold;
										color: #FFFFFF;
										line-height: 54px;
									}
									.lbt-carousel-node-desc {
										width: 463px;
										margin: 35px 0 10px;
										font-size: 24px;
										color: #FFFFFF;
										line-height: 32px;
									}
								}
								.lbt-carousel-node-more {
									// position: absolute;
									// left: 18.07%;
									// bottom: 0;
									width: 448px;
									height: 208px;
									background: #C3002F;
									cursor: pointer;
									.lbt-carousel-node-more-content {
										display: flex;
										align-items: center;
										margin-top: 27px;
										.lbt-carousel-node-more-content-text {
											margin-left: 30px;
											font-size: 24px;
											font-weight: 500;
											color: #FFFFFF;
											line-height: 24px;
										}
									}
								}
								.lbt-carousel-node-more:hover {
									.arrow-anim__line {
										-webkit-transform: scaleX(1.4);
										transform: scaleX(1.4);
									}
									.arrow-anim__image {
										-webkit-transform: translateX(6px) rotate(-45deg) !important;
										transform: translateX(6px) rotate(-45deg) !important;
									}
								}
							}
						}
					}
				}
			}
			.intro-box {
				.intro-box-title {
					font-size: 40px;
					font-weight: bold;
					color: #333333;
					line-height: 40px;
				}
				.intro-box-content {
					font-size: 18px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.btn:hover {
					background: #a60028;
				}
				.intro-box-more {
					width: 210px;
					height: 68px;
					font-size: 24px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
			.about-box {
				padding-bottom: 137px;
				.page-content-box {
					display: flex;
					.intro-box-left {
						padding-top: 111px;
					}
					.intro-box-right {
						margin-left: auto;
						padding-top: 187px;
					}
					.intro-box-content {
						width: 475px;
						margin: 30px 0 90px;
						line-height: 30px;
					}
				}
			}
			.news-box {
				background: #F7F8F8;
				padding: 83px 0 70px;
				.page-content-box {
					.intro-box-content {
						margin: 30px 0 66px;
						line-height: 24px;
					}
					.intro-box-list {
						.intro-box-list-node {
							cursor: pointer;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									display: block;
									overflow: hidden;
									max-width: 100%;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: 50%;
									background-color: #ccc;
									-webkit-transition: transform .3s ease-in-out;
									-webkit-transition: -webkit-transform .3s ease-in-out;
									transition: -webkit-transform .3s ease-in-out;
									transition: transform .3s ease-in-out;
									transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
									-webkit-transform-origin: center;
									transform-origin: center;
								}
								img:hover {
									-webkit-transform: scale(1.1);
									transform: scale(1.1);
								}
							}
							.intro-box-list-node-date {
								width: 100%;
								margin: 20px 0 10px;
								font-size: 14px;
								font-weight: 500;
								color: #898989;
								line-height: 14px;
							}
							.intro-box-list-node-title {
								width: 100%;
								font-size: 24px;
								color: #333333;
								line-height: 30px;
							}
							.intro-box-list-node-title:hover {
								color: #605a55;
							}
						}
					}
					.intro-box-more {
						margin: 0 auto;
						margin-top: 90px;
					}
				}
			}
			.product-box {
				padding: 83px 0 145px;
				.page-content-box {
					.intro-box-content {
						margin: 30px 0 75px;
						line-height: 24px;
					}
					.intro-box-list {
						.intro-box-list-node {
							margin-bottom: 55px;
							position: relative;
							cursor: pointer;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									display: block;
									overflow: hidden;
									max-width: 100%;
									background-repeat: no-repeat;
									background-size: cover;
									background-position: 50%;
									background-color: #ccc;
									-webkit-transition: transform .3s ease-in-out;
									-webkit-transition: -webkit-transform .3s ease-in-out;
									transition: -webkit-transform .3s ease-in-out;
									transition: transform .3s ease-in-out;
									transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
									-webkit-transform-origin: center;
									transform-origin: center;
								}
								img:hover {
									-webkit-transform: scale(1.1);
									transform: scale(1.1);
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								// justify-content: center;
								width: 100%;
								// height: 80px;
								// background: rgba(255, 255, 255, .9);
								position: absolute;
								bottom: 0;
								// left: calc(50% - 164px);
								img {
									width: 100%;
								}
								.intro-box-list-node-content-category {
									margin-bottom: 12px;
									font-size: 16px;
									font-weight: 500;
									color: #C3002F;
									line-height: 16px;
								}
								.intro-box-list-node-content-name {
									position: absolute;
									bottom: 27px;
									font-size: 24px;
									font-weight: 500;
									color: #fff;
									line-height: 24px;
									text-align: center
								}
							}
						}
					}
					.intro-box-more {
						margin: 0 auto;
					}
				}
			}
			.contract-box {
				position: relative;
				width: 100%;
				.contract-box-bg {
					display: block;
					width: 100%;
				}
				.contract-box-content {
					position: absolute;
					top: 0;
					left: 51.3%;
					width: 387px;
					height: 100%;
					.contract-box-content-title {
						margin: 84px 0 30px;
						font-size: 40px;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 40px;
					}
					.contract-box-content-desc {
						font-size: 18px;
						color: #FFFFFF;
						line-height: 24px;
					}
					.contract-box-content-more {
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 100px;
						background: #C3002F;
						cursor: pointer;
						.contract-box-content-more-box {
							display: flex;
							align-items: center;
							margin-top: 38px;
							.contract-box-content-more-box-text {
								margin-left: 30px;
								font-size: 24px;
								font-weight: 500;
								color: #FFFFFF;
								line-height: 24px;
							}
						}
					}
					.contract-box-content-more:hover {
						.arrow-anim__line {
							-webkit-transform: scaleX(1.4);
							transform: scaleX(1.4);
						}
						.arrow-anim__image {
							-webkit-transform: translateX(6px) rotate(-45deg) !important;
							transform: translateX(6px) rotate(-45deg) !important;
						}
					}

				}
			}
			/deep/.product-info-dialog-dom {
				z-index: 9999 !important;
				.product-info-dialog {
					width: 80%;
					height: 60%;
					z-index: 9999;
					.el-dialog__header {
						display: none;
					}
					.el-dialog__body {
						width: 100%;
						height: 100%;
						padding: 0;
					}
				}
			}
		}
		.mobile-content-box {
			.arrow-anim__arrow {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				margin-left: 16px;
				padding-top: 2px;
				.arrow-anim__line {
					width: 18px;
					height: 2px;
					background: #fff;
					-webkit-transition: transform .3s ease-in-out;
					-webkit-transition: -webkit-transform .3s ease-in-out;
					transition: -webkit-transform .3s ease-in-out;
					transition: transform .3s ease-in-out;
					transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
				}
				.arrow-anim__image {
					border: solid #fff;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
					-webkit-transition: transform .2s ease-in-out;
					-webkit-transition: -webkit-transform .2s ease-in-out;
					transition: -webkit-transform .2s ease-in-out;
					transition: transform .2s ease-in-out;
					transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
					-webkit-transform-origin: center;
					transform-origin: center;
				}
			}
			.arrow-anim__arrow--right {
				.arrow-anim__line {
					-webkit-transform-origin: left center;
					transform-origin: left center;
				}
				.arrow-anim__image {
					-webkit-transform: rotate(-45deg);
					transform: rotate(-45deg);
					margin-left: -7px;
				}
			}
			.lbt-dom {
				position: relative;
				width: 100%;
				.lbt-carousel-node-more-box {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					z-index: 10;
					.lbt-carousel-node-more-box-dom {
						.lbt-carousel-node-more {
							display: flex;
							align-items: center;
							position: absolute;
							left: 5.68%;
							bottom: 0;
							width: 88.64%;
							height: 57px;
							background: #C3002F;
							.lbt-carousel-node-more-content {
								display: flex;
								align-items: center;
								.lbt-carousel-node-more-content-text {
									margin-left: 28px;
									font-size: 21px;
									font-weight: 500;
									color: #FFFFFF;
								}
							}
						}
					}
				}
				.lbt-carousel {
					/deep/.el-carousel__container {
						.el-carousel__arrow {
							display: none;
						}
					}
					/deep/.el-carousel__indicators {
						display: none;
					}
					.lbt-carousel-node {
						position: relative;
						img {
							display: block;
							width: 100%;
						}
						.lbt-carousel-node-content {
							position: absolute;
							top: 8.07%;
							left: 7.25%;
							.lbt-carousel-node-title {
								font-size: 41px;
								font-weight: bold;
								color: #FFFFFF;
								line-height: 41px;
							}
							.lbt-carousel-node-desc {
								padding-right: 8.69%;
								margin-top: 22px;
								font-size: 18px;
								color: #FFFFFF;
								line-height: 27px;
							}
						}
						.lbt-carousel-node-more {
							display: flex;
							align-items: center;
							position: absolute;
							left: 5.68%;
							bottom: 0;
							width: 88.64%;
							height: 57px;
							background: #C3002F;
							.lbt-carousel-node-more-content {
								display: flex;
								align-items: center;
								.lbt-carousel-node-more-content-text {
									margin-left: 28px;
									font-size: 21px;
									font-weight: 500;
									color: #FFFFFF;
								}
							}
						}
					}
				}
			}
			.intro-box {
				.intro-box-title {
					font-size: 28px;
					font-weight: bold;
					color: #333333;
					line-height: 28px;
				}
				.intro-box-content {
					font-size: 16px;
					color: #333333;
				}
				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #C3002F;
					cursor: pointer;
					user-select: none;
				}
				.intro-box-more {
					height: 57px;
					font-size: 21px;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
			.about-box {
				padding-bottom: 38px;
				.page-content-box {
					.intro-box-left {
						padding-top: 43px;
					}
					.intro-box-right {
						margin-left: auto;
						padding-top: 30px;
						img {
							width: 100%;
						}
					}
					.intro-box-content {
						line-height: 26px;
						margin: 30px 0;
					}
				}
			}
			.news-box {
				background: #F7F8F8;
				padding: 36px 0 57px;
				.page-content-box {
					.intro-box-content {
						margin: 17px 0 30px;
						line-height: 16px;
					}
					.intro-box-list {
						.intro-box-list-node {
							padding: 30px 0;
							border-bottom: 1px solid #ededed;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									display: block;
									max-width: 100%;
								}
							}
							.intro-box-list-node-date {
								width: 100%;
								margin: 20px 0 10px;
								font-size: 14px;
								font-weight: 500;
								color: #898989;
								line-height: 14px;
							}
							.intro-box-list-node-title {
								width: 100%;
								font-size: 21px;
								color: #333333;
								line-height: 27px;
							}
						}
						.el-col:first-child {
							.intro-box-list-node {
								padding-top: 0;
							}
						}
						.el-col:last-child {
							.intro-box-list-node {
								border-bottom: none;
								padding-bottom: 43px;
							}
						}
					}
					.intro-box-more {
						margin: 0 auto;
						margin-top: 15px;
					}
				}
			}
			.product-box {
				padding: 43px 0;
				.page-content-box {
					.intro-box-content {
						margin: 17px 0 36px;
						line-height: 24px;
					}
					.intro-box-list {
						.intro-box-list-node {
							margin-bottom: 35px;
							position: relative;
							.intro-box-list-node-img {
								overflow: hidden;
								img {
									display: block;
									width: 100%;
								}
							}
							.intro-box-list-node-content {
								display: flex;
								flex-direction: column;
								align-items: center;
								// justify-content: center;
								width: 100%;
								// height: 40px;
								// background: rgba(255, 255, 255, .9);
								position: absolute;
								bottom: 0;
								// left: 6.25%;
								img {
									width: 100%;
								}
								.intro-box-list-node-content-category {
									margin-bottom: 9px;
									font-size: 13px;
									font-weight: 500;
									color: #C3002F;
									line-height: 13px;
								}
								.intro-box-list-node-content-name {
									position: absolute;
									bottom: 27px;
									font-size: 14px;
									font-weight: 500;
									color: #fff;
									line-height: 14px;
									text-align: center;
								}
							}
						}
					}
				}
			}
			.contract-box {
				position: relative;
				width: 100%;
				.contract-box-bg {
					display: block;
					width: 100%;
				}
				.contract-box-content {
					position: absolute;
					top: 0;
					left: 7.25%;
					height: 100%;
					.contract-box-content-title {
						margin: 56px 0 15px;
						font-size: 28px;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 28px;
					}
					.contract-box-content-desc {
						font-size: 16px;
						color: #FFFFFF;
						line-height: 23px;
					}
					.contract-box-content-more {
						display: flex;
						align-items: center;
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 57px;
						background: #C3002F;
						.contract-box-content-more-box {
							display: flex;
							align-items: center;
							.contract-box-content-more-box-text {
								margin-left: 28px;
								font-size: 21px;
								font-weight: 500;
								color: #FFFFFF;
								line-height: 21px;
							}
						}
					}

				}
			}
			/deep/.product-info-dialog {
				width: 80%;
				height: 60%;
				z-index: 9999;
				.el-dialog__header {
					display: none;
				}
				.el-dialog__body {
					width: 100%;
					height: 100%;
					padding: 0;
				}
			}
		}
	}
</style>
<style scoped lang="scss">
  .app-en {
    .app-pc {
		.intro-box-content {
			line-height: 24px !important;
		}
		.news-box {
			.intro-box-list-node-title {
				line-height: 26px !important;
			}
		}
    }
	.app-mobile {
		.lbt-dom {
			.lbt-carousel-node-title {
				font-size: 36px !important;
				line-height: 36px !important;
			}
			.lbt-carousel-node-desc {
				margin-top: 14px !important;
				font-size: 16px !important;
				line-height: 18px !important;
			}
		}
		.about-box {
			.intro-box-content {
				font-size: 16px !important;
				line-height: 21px !important;
			}
		}
		.news-box {
			.intro-box-list-node-title {
				font-size: 21px !important;
				line-height: 23px !important;
			}
		}
		.product-box {
			.intro-box-content {
				font-size: 16px !important;
				line-height: 21px !important;
			}
			.intro-box-list-node-content-category {
				font-size: 12px !important;
				line-height: 12px !important;
				margin-bottom: 7px !important;
			}
			.intro-box-list-node-content-name {
				font-size: 13px !important;
				line-height: 13px !important;
			}
		}
		.contract-box {
			.contract-box-content-desc {
				font-size: 16px !important;
				line-height: 21px !important;
			}
		}
	}
  }
  .app-ja {
    .app-pc {
		.product-box {
			.intro-box-list-node-content-category {
				margin-bottom: 10px !important;
			}
			.intro-box-list-node-content-name {
				padding: 0 30px !important;
				font-size: 20px !important;
				line-height: 22px !important;
			}
		}
	}
	.app-mobile {
		.lbt-dom {
			.lbt-carousel-node-title {
				font-size: 22px !important;
				line-height: 22px !important;
			}
			.lbt-carousel-node-desc {
				margin-top: 14px !important;
				font-size: 16px !important;
				line-height: 23px !important;
			}
		}
		.product-box {
			.intro-box-content {
				line-height: 23px;
			}
			.intro-box-list-node-content-category {
				margin-bottom: 5px !important;
			}
			.intro-box-list-node-content-name {
				font-size: 10px !important;
				padding: 0 5px !important;
			}
		}
	}
  }
</style>
